<template>
  <path
    style="
      fill: none;
      stroke: currentColor;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
    "
    d="M23 18.111h-3.666"
  />
  <path
    data-name="primary"
    style="
      fill: none;
      stroke: currentColor;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
    "
    d="m21.778 23-6.979-12.565M12 5.4 9.555 1M14.445 1 2.222 23M13.222 18.111H1"
  />
</template>
