<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';

const { $buildClass, $queries } = useNuxtApp();

const { logPageView } = useAnalytics();

const breadcrumb = useBreadcrumb('download-app');
const title = 'Download app';

const { isLoading, data } = useQuery($queries().pages.bySlug('download-app'));
useMeta();

logPageView(title);
</script>

<template>
  <ThePage :class="$buildClass()">
    <NuxtLayout>
      <template #full>
        <TheBreadcrumb :crumbs="breadcrumb" />
        <PageTitle>{{ title }}</PageTitle>
      </template>
      <DownloadApp />
    </NuxtLayout>
  </ThePage>
</template>

<style scoped lang="scss"></style>
